@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Raleway', sans-serif;
    @apply bg-[#cb7656] text-white;
}


/* .name h1 {
    font-weight: 600;
} */

.info {
    @apply bg-white h-[1px]
}

@media(max-width: 375px) {
    .header-h1 {
        @apply text-xl;
    }
}